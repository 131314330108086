const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const getUrlParameter = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

const mainLoad = async () => {
  const tgScript = document.createElement('script');
  tgScript.src = 'https://telegram.org/js/telegram-web-app.js';
  tgScript.defer = true;
  document.body.appendChild(tgScript);

  try {
    await import('./src/main');
  } catch (error) {
    console.error('Ошибка загрузки основного модуля:', error);
  }
};

const prerenderLoad = async () => {
  try {
    // Импортирование IMask и пререндерного скрипта
    await Promise.all([
      import('./src/prerender/imask@7.6.1/imask.js'),
      import('./src/prerender/prerendered'),
    ]);
  } catch (e) {
    console.error('Ошибка загрузки пререндерных модулей:', e);
  }
};

const token = getCookie('token');
const tg = getUrlParameter('tg');
const cio_id = getUrlParameter('cio_id');
const hash = getUrlParameter('hash');
const appReady = getCookie('app-ready');
const appReadyLS = localStorage.getItem('app-ready');

const isMain = token || tg || cio_id || hash || appReady || appReadyLS;

if (!isMain) {
  prerenderLoad();
} else {
  mainLoad();
}
